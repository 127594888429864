<template>
<div>
    <div class="px-3">
        <div class="d-flex items-center mt-5 md:mt-0 justify-end">
            <span class="font-semibold mr-3">SHARE:</span>
            <div class="share-network-list">
                <ShareNetwork v-for="network in networks" :network="network.network" :key="network.network" :style="{backgroundColor: network.color}" :url="url" title="">
                    <feather-icon :icon="network.icon" svgClasses="w-5 h-5" ></feather-icon>
                </ShareNetwork>
            </div>
            <div  class="cursor-pointer">
                <i class="fa fa-globe share-icon fa-2x" @click="customShare(feed)"></i>
            </div>
        </div>
        <!-- <vs-divider></vs-divider> -->
    </div>

    <!-- <div class="flex items-center mb-2">
      <span class="mr-2">Channel</span>
      <vs-select
        v-model="selectedChannel">
        <vs-select-item :key="index" :value="channel.id" :text="channel.name" v-for="(channel, index) in channels" />
      </vs-select></div> -->
    <!-- <div class="post-header flex">
      <div class="w-full">
          <CpTextarea ref="cpTextarea" @input="updateInput"/>
      </div>
    </div> -->

    <!-- <ProgressBar ref="uploadProgressbar" />
    <div>
        <vs-images>
            <vs-image :key="index" :src="image.data" v-for="(image, index) in prevImages" />
        </vs-images>
    </div> -->

    <!-- <Post :actor="''" :action="''" v-if="sharePost" :feed="sharePost" :displayComment="false" :displayPostOption="false" :displayLikeAndShare="false" :displayShare="false" /> -->

    <div class="">
        <div class="vx-col w-full">
            <vx-card no-shadow class="share-post-card">

                <div class="flex items-center mb-5">
                    <avatar style="margin-left:5px;" v-if="feed.authorRef.photoURL.split('/')[feed.authorRef.photoURL.split('/').length-1] === 'default_dp.png'" class="border-2 border-solid border-white mr-2 my-1" :username="feed.authorRef.displayName" :size="42" />

                    <vs-avatar class="m-0" :src="feed.authorRef.photoURL" size="40px" v-else />
                    <div class="leading-tight ml-3">
                        <h5 class="feed-author font-semibold mb-0">{{ feed.authorRef.displayName }}</h5>
                        <span class="flex items-center">{{ feed.authorRef.studyStatus }}</span>
                    </div>
                </div>
                <div class="mt-5">
                    <!-- <h5 class="mb-2">Title Here</h5> -->
                    <p class="">{{feed.content}}</p>
                </div>

                <div v-if='feed.mediaFiles.length!=0'>
                    <div class="w-full mt-base" v-for='media in feed.mediaFiles' :key='media'>
                        <img :src="media" alt="content-img" class="responsive">

                    </div>
                </div>
                <div class="w-full mt-base" v-if='feed.video!=null'>
                    <video :src="this.video" id="video-container" controls></video>
                    <!-- <video-player ref="video" class="media-video-player card-video" :options="playerOptions" /> -->
                </div>

                <!-- <vs-divider></vs-divider> -->
            </vx-card>
        </div>
    </div>

    <div class="og-card" v-if="hasOpenGraph">
        <vs-card v-if="!ogLoading" class="cursor-pointer z-50">
            <feather-icon icon="XCircleIcon" class="og-close-button" svgClasses="h-8 w-8 mb-1 text-primary" @click="hasOpenGraph = false"></feather-icon>
            <div class="static og-container" @click="goToWebsite">
                <div>
                    <div slot="media" v-if="openGraphData.ogImage">
                        <img :src="openGraphData.ogImage.url" class="og-img" />
                    </div>
                    <vs-divider />
                    <div>
                        <h5 class="mt-2">{{ openGraphData.ogTitle | truncate(50) }}</h5>
                        <span v-if="openGraphData.ogSiteName">{{ openGraphData.ogSiteName  }}</span>
                        <p class="text-base">{{ openGraphData.ogDescription }}</p>
                    </div>
                </div>
            </div>
        </vs-card>
        <div class="block loading" v-else>
            <div class="loading">
                <div class="effect-1 effects"></div>
                <div class="effect-2 effects"></div>
                <div class="effect-3 effects"></div>
            </div>
        </div>
    </div>

    <div class="mt-4">
        <!-- <div class="flex" v-if="!sharePost">
        <div class="img-btn">
          <vs-button color="dark" type="line" icon="image" @click="uploadImage">
            <input
              ref="imageInput"
              type="file"
              accept="image/*"
              @change="onImageChange"
              multiple
              class="hidden"
            />
          </vs-button>
        </div>
      </div> -->
        <!-- <div class="flex float-right">
          <EmojiPickerWrapper v-on:handleInsertEmoji="insert" />
          <vs-button
            @click="onSubmitPost"
            color="primary"
            type="filled"
            >Post</vs-button
          >
      </div> -->
    </div>
</div>
</template>

<script>
import CpTextarea from '@/components/CpTextarea'
import Avatar from 'vue-avatar'
import ProgressBar from '@/layouts/components/coursepal/ProgressBar'
import EmojiPickerWrapper from '@/layouts/components/coursepal/EmojiPickerWrapper'
import axios from 'axios'

export default {
    name: 'PostInputPopup',
    props: {
        userImage: String,
        isActive: Boolean,
        feed: Object
    },
    components: {
        ProgressBar,
        EmojiPickerWrapper,
        CpTextarea,
        Avatar,
        Post: () => import('@/layouts/components/coursepal/Post/Post')
    },
    data() {
        return {
            input: '',
            search: '',
            prevImages: [],
            images: [],
            videos: [],
            hasOpenGraph: false,
            openGraphData: {},
            crawledUrl: '',
            ogLoading: false,
            sharePost: null,
            selectedChannel: 4,
            url: 'https://mypals.app/',
            networks: [{
                    network: 'facebook',
                    name: 'Facebook',
                    icon: 'FacebookIcon',
                    color: '#1877f2'
                },
                {
                    network: 'linkedin',
                    name: 'LinkedIn',
                    icon: 'LinkedinIcon',
                    color: '#007bb5'
                },
                {
                    network: 'twitter',
                    name: 'Twitter',
                    icon: 'TwitterIcon',
                    color: '#1da1f2'
                },
                {
                    network: 'whatsapp',
                    name: 'Whatsapp',
                    icon: 'MessageCircleIcon',
                    color: '#25d366'
                },
                // { network: 'webURL', name: 'Custom Network', icon: 'GlobeIcon', color: '#00A8F4' }
            ],
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any() && this.input !== ''
        },
        channels() {
            const PUBLIC_CHANNEL_ID = 4
            const res = [{
                    name: 'Public',
                    id: PUBLIC_CHANNEL_ID
                },
                {
                    name: 'Unifeed',
                    id: this.$store.state.channel.universityChannel.channel_id
                }
            ]
            for (const ic of this.$store.state.channel.interestedChannels) {
                res.push({
                    name: ic.channel_name,
                    id: ic.channel_id
                })
            }

            return res
        }
    },
    // this.sharePost.id
    methods: {
        insert(emoji) {
            this.input += emoji
        },

        onSubmitPost(e) {
            e.preventDefault()
            if (!this.validateForm) return
            const medias = [...this.images, ...this.videos]
            const postData = {
                author: this.$store.state.auth.userData.name,
                content: this.input,
                medias,
                uploadProgressbar: this.$refs.uploadProgressbar,
                channelID: this.selectedChannel,
                sharePostID: this.sharePost ? this.sharePost.id : '',
                $vs: this.$vs
            }
            this.$store.dispatch('newsfeed/createPost', postData).then(() => {
                this.input = ''
                this.prevImages = []
                this.images = []
                this.videos = []
            }).then(() => this.$emit('postSubmitted'))
        },
        uploadImage() {
            this.$refs.imageInput.click()
        },
        onImageChange(e) {
            this.prevImages = []
            this.images = e.target.files
            for (const image of this.images) {
                const fr = new FileReader()
                fr.onload = (function (prevImages) {
                    return function (e) {
                        prevImages.push({
                            name: image.name,
                            type: image.type,
                            data: e.target.result
                        })
                    }
                })(this.prevImages)
                fr.readAsDataURL(image)
            }
        },
        updateInput() {
            this.input = this.$refs.cpTextarea.$el.innerText
            const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig
            const matches = this.input.match(urlRegex)
            if (matches && matches.length > 0) {
                const lastMatched = matches[matches.length - 1]
                if (this.crawledUrl !== lastMatched) {
                    this.hasOpenGraph = true
                    this.crawledUrl = lastMatched
                    this.ogLoading = true
                    axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/getOpenGraphJSON`, {
                        url: lastMatched
                    }).then(res => {
                        if (res.data.results) {
                            const {
                                ogDate,
                                ogDescription,
                                ogImage,
                                ogSiteName,
                                ogTitle,
                                ogUrl,
                                ogVideo,
                                requestUrl
                            } = res.data.results
                            this.openGraphData = {
                                ogDate,
                                ogDescription,
                                ogImage,
                                ogSiteName,
                                ogTitle,
                                ogUrl,
                                ogVideo,
                                requestUrl
                            }
                        }
                        this.ogLoading = false
                    })
                }
            }
        },
        goToWebsite() {
            window.open(this.openGraphData.requestUrl, '__blank')
        },
       async customShare(feed) { 
           //  await axios.post('https://api.coursepal.app/api/createhtmlimagewithcontent', {
              //      content:'Testing Image'
             //   }).then(function (response) {
            //        console.log(response);
            //    })
            window.open(`https://mypals.app/post?postId=${this.feed.id}`, '__blank')

        }
    }
}
</script>

<style scoped>
.og-close-button {
    position: relative !important;
    z-index: 1000;
    left: 262px;
    top: 18px;
}

.og-container:hover {
    opacity: 0.5;
}

.og-img {
    object-fit: cover;
    width: 100%;
    max-height: 300px;
}

.og-loading {
    width: 100%;
    height: 300px;
}

.og-card {
    min-height: 300px;
}

.share-network-list a {
    /* padding-top: 10px !important; */
}

.share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    /* margin: auto; */
}

a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    margin: 0 10px 0px 0;
}

a[class^="share-network-"] .feather-icon {
    /* background-color: rgba(0, 0, 0, 0.2);
     padding: 10px; */
    padding: 5px;
    flex: 0 1 auto;
}

a[class^="share-network-"] span {
    padding: 0 10px;
    flex: 1 1 0%;
    font-weight: 500;
}
</style>
