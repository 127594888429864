<template>
<li v-if="comment.authorRef" class="commented-user flex items-start mb-2">
    <div class="leading-tight w-full">
        <!-- user profile -->
        <div class="flex items-center">
            <div class="flex items-center user-profile-group-btn cursor-pointer w-full" @click="goToProfilePage">
                <AvatarHolder :photoURL="comment.authorRef.photoURL" :profileData="comment.authorRef" :size="'28px'" />
                <div class="ml-2 text-lg block font-semibold w-full">
                    {{ comment.authorRef.displayName }}
                </div>
            </div>

            <div class="ml-auto" v-if="comment.author === currentUsername">
                <vs-dropdown vs-custom-content vs-trigger-click class="vx-navbar-dropdown" vs-collapse>
                    <vs-button type="flat" color="dark" icon="more_horiz" size="large" class="ml-4"></vs-button>
                    <vs-dropdown-menu>
                        <ul>
                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="editComment(comment, feedIndex, commentIndex)">
                                <span>Edit comment</span>
                            </li>
                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="deleteComment(comment, feedIndex, commentIndex)">
                                <span>Delete comment</span>
                            </li>
                        </ul>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>
        </div>

        <!-- edit comment dropdown -->

        <!-- comment content -->
        <div class="text-md comment-content" :class="{ hidden: isEditCommentActive }" v-html="$options.filters.formatPostContent(comment.content)"></div>

        <!-- edit comment textarea -->
        <div class="mt-4 justify-start" :class="{ hidden: !isEditCommentActive }">
            <CpTextarea ref="editCommentTextarea" @input="updateEditCommentInput" />
            <div class="mt-2">
                <vs-button size="small" @click="submitEditComment(comment, feedIndex, commentIndex)" :disabled="editCommentInput.length === 0">Update</vs-button>
                <vs-button class="ml-2" type="border" color="primary" size="small" @click="cancelEditComment">Cancel</vs-button>
            </div>
        </div>

        <!-- like and comment buttons -->
        <div class="mt-4 mb-2">
            <div class="flex items-start justify-start">

                <div class="flex items-center cursor-pointer subcomment-like-btn" @click="toggleLikeComment(feedIndex, commentIndex, comment)">
                    <feather-icon icon="HeartIcon" :svgClasses="{
              'h-5 w-5 text-danger fill-current stroke-current':
                isLiked,
              'h-5 w-5 stroke-current': !isLiked,
            }" class="mr-2 cursor-pointer"></feather-icon>
                    <span class="mr-2">Like</span>
                </div>

                <div class="flex items-center cursor-pointer ml-2 subcomment-btn" @click="toggleSubCommentInput">
                    <feather-icon icon="MessageSquareIcon" svgClasses="h-5 w-5" class="cursor-pointer mr-2"></feather-icon>
                    <span>Reply</span>
                </div>

                <div class="flex items-center ">
                    <a v-if="comment.subComments.items.length > 0" class="view-all-link flex items-center text-md ml-3 cursor-pointer" @click="toggleViewAllSubComments">
                        <span>{{ viewAllSubComments ? "Hide " : "View " }} {{ comment.subComments.items.length }} replies</span>
                        <feather-icon :icon="$vs.rtl ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="h-5 w-5"></feather-icon>
                    </a>
                </div>

            </div>

            <!-- subcomment -->
            <SubComment v-if="comment.subComments.items.length > 0 && viewAllSubComments" :data="comment.subComments.items" :isEditCommentActive="isEditCommentActive" :feedIndex="feedIndex" :commentIndex="commentIndex" :comment="comment" @onCreateSubComment="toggleReplyFromSubComment" />
            <!-- end subcomment -->

            <!-- add subcomment textarea -->
            <div :class="{ hidden: !isCreateSubCommentActive }">
                <CpTextarea ref="subCommentTextarea" @input="updateSubCommentInput" placeholder="Write your reply here..." />
                <div class="mt-2">
                    <vs-button size="small" @click="submitSubComment(comment)" :disabled="subCommentInput.length === 0">Reply</vs-button>
                    <vs-button class="ml-2" type="border" size="small" @click="cancelAddSubComment">Cancel</vs-button>
                </div>
            </div>
            <!-- end add subcomment textarea -->
        </div>
    </div>
</li>
</template>

<script>
import SubComment from './SubComment'
import ProfileHoverCard from './ProfileHoverCard'
import AvatarHolder from './AvatarHolder'
import CpTextarea from '@/components/CpTextarea'

export default {
    name: 'CommentItem',
    components: {
        SubComment,
        ProfileHoverCard,
        AvatarHolder,
        CpTextarea
    },
    data() {
        return {
            isEditCommentActive: false,
            isCreateSubCommentActive: false,
            subCommentInput: '',
            editCommentInput: '',
            viewAllSubComments: false,
            isLikedState: this.comment.isLiked,
        }
    },
    computed: {
        currentUsername() {
            return this.$store.state.auth.userData.name
        },
        isLiked: {
            get() {
                return this.isLikedState
            },
            set(val) {
                this.isLikedState = val
            }
        }
    },
    watch: {
        comment(newVal) {
            this.isLiked = newVal.isLiked;
        },
    },
    props: ['feed', 'comment', 'feedIndex', 'commentIndex'],
    methods: {
        editComment(comment) {
            this.editCommentInput = comment.content

            this.$refs.editCommentTextarea.$el.innerText = comment.content
            this.$refs.editCommentTextarea.createHashtagElement()

            this.isEditCommentActive = true
        },
        submitEditComment(comment, feedIndex, commentIndex) {
            this.$store.dispatch('newsfeed/editComment', {
                feed: this.feed,
                editCommentInput: this.editCommentInput,
                comment,
                feedIndex,
                commentIndex
            })
            this.isEditCommentActive = false
        },
        cancelEditComment() {
            this.isEditCommentActive = false
        },
        deleteComment(comment, feedIndex, commentIndex) {
            this.$store.dispatch('newsfeed/deleteComment', {
                feed: this.feed,
                comment,
                commentIndex
            }).then(() => {
                this.isEditCommentActive = false
            })
        },
        toggleLikeComment(feedIndex, commentIndex, comment) {
            this.$store.dispatch('newsfeed/toggleLikeComment', {
                feedIndex,
                commentIndex,
                comment
            }).then(() => {
                this.isLiked = !this.isLiked;
            });
        },
        toggleSubCommentInput() {
            this.isCreateSubCommentActive = !this.isCreateSubCommentActive
            this.$emit('onCreateSubComment', this.isCreateSubCommentActive)
        },
        toggleReplyFromSubComment() {
            this.isCreateSubCommentActive = true
            this.$emit('onCreateSubComment', true)
        },
        submitSubComment(comment) {
            this.$store.dispatch('newsfeed/createSubComment', {
                comment,
                subCommentInput: this.subCommentInput
            }).then(() => {
                this.subCommentInput = ''
                this.$refs.subCommentTextarea.$el.innerText = ''
                this.isCreateSubCommentActive = false
                this.$emit('onCreateSubComment', false)
            })

            
            if (this.feed.authorRef.enablePostNotification !== false) {

                this.$store.dispatch('newsfeed/sendMail', {
                    user_name: this.$store.state.auth.userData.name,
                    activity_user: this.feed.author,
                    activity: 'REPLY',
                    template_code: 'send_notification',
                    activity_user_mail: this.feed.authorRef.email,
                    post_content: this.feed.content,
                    activity_user_image_url: this.feed.authorRef.photoURL,
                    post_image_url: this.feed.mediaFiles[0],
                    profile_url: this.$store.state.auth.userData.photoURL,
                    post_url: `https://mypals.app/post?postId=${this.feed.id}`
                })
            }
        },
        cancelAddSubComment() {
            this.isCreateSubCommentActive = false
            this.$emit('onCreateSubComment', false)
        },
        toggleViewAllSubComments() {
            this.viewAllSubComments = !this.viewAllSubComments
        },
        updateEditCommentInput() {
            this.editCommentInput = this.$refs.editCommentTextarea.$el.innerText
        },
        updateSubCommentInput() {
            this.subCommentInput = this.$refs.subCommentTextarea.$el.innerText
        },
        goToProfilePage() {

            if (this.comment.authorRef.isPartner) {
                this.$router.push(`/partner/${this.comment.author}`)
            } else {
                this.$router.push(`/profile/${this.comment.author}`)
            }
        }
    }
}
</script>

<style lang="scss">
.comment-content {
    margin-top: 0.1rem;
    padding: 0.8rem !important;
    border-radius: 5%;
}

.circle {
    border-radius: 50% !important;
}

.subcomment-btn,
.subcomment-like-btn {
    height: 30px;
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 10px;
}

.subcomment-btn:hover,
.subcomment-like-btn:hover {
    background-color: #ddd;
}

.view-all-link {
    height: 30px;
}

.user-profile-group-btn {
    border-radius: 10px;
    padding: 0.2rem 0.5rem;
}

.user-profile-group-btn:hover {
    background-color: #ddd;
}
</style>
