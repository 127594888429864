<template>
<!-- POST HEADER -->
<div class="post-header flex justify-between mb-4">

    <div class="md:flex md:items-start cursor-pointer mr-2">
        <div class="flex">
            <template v-if="feed.authorRef.isPartner">
                <AvatarHolder :profileData="feed.authorRef" :partnerData="feed.partnerData" :photoURL="feed.authorRef.photoURL" :displayName='feed.authorRef.displayName' :size="'40px'" />
            </template>
            <template v-else>
                <AvatarHolder :profileData="feed.authorRef" :photoURL="feed.authorRef.photoURL" :displayName='feed.authorRef.displayName' :size="'40px'" />
            </template>
            <div class="flex-3 ml-4 ">
                <h5 class="mb-0 font-semibold post-header-display-name">{{ feed.authorRef.displayName }}</h5>
                <span v-if="!feed.authorRef.isPartner " class="text-base">{{ feed.authorRef.studyStatus }}</span>

                <span v-if="feed.partnerData" class="text-base">{{ feed.partnerData.partnerType | partnerTypeFormatter }}</span>
                <feather-icon v-if="feed.authorRef.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-success rounded-full text-white"></feather-icon>
            </div>

            <PostOptionDropdown v-if="displayPostOption" :feed="feed" :feedIndex="feedIndex" @toggleEditPostForm="toggleEditPostForm" />
        </div>

    </div>

    <div v-if="displayLikeAndShare" class="flex flex-col justify-end like-button">
        <div class="flex flex-row-reverse">
            <div v-if="displayShare" @click="toggleSharePost(feed)">
                <i class="fa fa-share share-icon ml-2"></i>
            </div>

            <div @click="toggleLikePost(feed)">
                <feather-icon class="mr-2" icon="HeartIcon" :svgClasses="{'text-danger fill-current stroke-current': isLiked }">
                </feather-icon>
            </div>

        </div>
        <div class="flex-1">
            <span class="text-sm">{{ feed.createdAt | elapsedTime }}</span>
        </div>
    </div>
    <vs-popup title="Share your post" :active.sync="popupActive">
        <PostInputPopup ref="postInputRef" @postSubmitted="popupActive = false" :feed="feed" />
    </vs-popup>

</div>
</template>

<script>
import PostOptionDropdown from './PostOptionDropdown'
import Avatar from 'vue-avatar'

import AvatarHolder from '../AvatarHolder'
import PostInputPopup from '../mobile/PostInputPopup'
import axios from 'axios'

import vSelect from 'vue-select'

import {
    API,
    graphqlOperation
} from '@aws-amplify/api'
import {
    updatePost
} from '@/graphql/updatePost'
// import {
//     listCategorys
// } from "@/graphql/queries";
import {
    createErrorObject,
    createSuccessObject
} from '@/utils'
import {
    Storage
} from '@aws-amplify/storage'

// const providerOptions = {
//     binancechainwallet: {
//         package: true
//     },
//     walletconnect: {
//         package: WalletConnectProvider, // required
//         options: {
//             rpc: {
//                 80001: process.env.VUE_APP_RPC
//             }
//         },
//     },
//     walletlink: {
//         package: WalletLink,
//         options: {
//             appName: "MyPals",
//             //   infuraId: "3060571af4574f2d8ffc1f753de4e578", 
//             rpc: {
//                 80001: process.env.VUE_APP_RPC
//             },
//             chainId: 1,
//             appLogoUrl: null,
//             darkMode: true
//         }
//     },
//     coinbasewallet: {
//         package: CoinbaseWalletSDK, // Required
//         options: {
//             appName: "MyPals", // Required
//             //   infuraId: "3060571af4574f2d8ffc1f753de4e578", // Required
//             rpc: {
//                 80001: process.env.VUE_APP_RPC
//             },
//             chainId: 1, // Optional. It defaults to 1 if not provided
//             darkMode: false // Optional. Use dark theme, defaults to false
//         }
//     }
// };

// const web3Modal = new Web3Modal({
//     network: "mumbai", // optional -- mainnet
//     cacheProvider: true, // optional
//     providerOptions, // required
// });

// const abi = MyPalsNFT.abi;
// const traderAbi = MyPalsTrader.abi;

export default {
    props: ['feed', 'feedIndex', 'displayPostOption', 'displayLikeAndShare', 'displayShare'],
    components: {
        PostOptionDropdown,
        AvatarHolder,
        PostInputPopup,
        'v-select': vSelect,
      
        Avatar

    },
    data() {
        return {
            isLikedState: this.feed.isLiked,
            popupActive: false,
        
         
           
            selectedFeed: {},
    
            title: '',
        
          
            categories: [],
          
            showApprovedForAll: false,
            video: '',
          
            external_link: '',
            balance: '',
            user: '',
    
        }
    },
    created() {
        // this.fetchCategory();
   

    },
    computed: {
        currentUser() {
            return this.$store.state.auth.userData.name
        },
      
        isLiked: {
            get() {
                return this.isLikedState
            },
            set(val) {
                this.isLikedState = val
            }
        }
    },
    watch: {
        feed(newVal) {
            this.isLiked = newVal.isLiked;
        },
    },

    methods: {



        // async fetchCategory() {
        //     try {
        //         const res = await API.graphql(graphqlOperation(listCategorys))
        //         this.categories = res.data.listCategorys.items.map(item => {
        //             return {
        //                 text: item.name,
        //                 val: item.id
        //             }
        //         })
        //     } catch (err) {
        //         console.log('fetchCategory', err)
        //     }
        // },
     

        async toggleLikePost(feed) {

            this.$store.dispatch('newsfeed/toggleLikePost', {
                feed
            }).then(() => {
                this.isLiked = !this.isLiked
            })

            if (!this.isLiked) {

                if (this.feed.authorRef.enablePostNotification == true) {
                    this.$store.dispatch('newsfeed/sendMail', {
                        user_name: this.$store.state.auth.userData.name,
                        activity_user: this.feed.authorRef.displayName,
                        activity: 'LIKE',
                        template_code: 'send_notification',
                        activity_user_mail: this.feed.authorRef.email,
                        post_content: this.feed.content,
                        activity_user_image_url: this.feed.authorRef.photoURL,
                        post_image_url: this.feed.mediaFiles[0],
                        profile_url: this.$store.state.auth.userData.photoURL,
                        post_url: `https://mypals.app/post?postId=${this.feed.id}`

                    })
                }
            }

        },
        toggleSharePost(feed) {
            if (feed.sharePost) {
                this.$refs.postInputRef.sharePost = feed.sharePost
                this.$refs.postInputRef.url = `https://mypals.app/post?postId=${feed.sharePost.id}`;
            } else {
                this.$refs.postInputRef.sharePost = feed
                this.$refs.postInputRef.url = `https://mypals.app/post?postId=${feed.id}`;
            }
            this.popupActive = true
            // window.open(
            //     `https://mypals.app/post?postId=${feed.id}`, "_blank");

        },
        toggleEditPostForm() {
            this.$emit('toggleEditPostForm')
        },
        goToProfilePage() {
            if (this.feed.authorRef.isPartner) {
                this.$router.push(`/partner/${this.feed.author}`)
            } else {
                this.$router.push(`/profile/${this.feed.author}`)
            }
        }
    }
}
</script>

<style scoped>
.like-button:hover {
    cursor: pointer;
}

.user-profile-group-btn {
    /* border-radius: 10px; */
    padding: 0.5rem 0.5rem;
}

.share-icon {
    font-size: 1.5rem;
    margin-top: 2px;
}

.post-header-display-name {
    word-break: break-word;
    white-space: normal !important;
}
</style>
