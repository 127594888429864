<template>
<div class="comments-container mt-2">
    <ul class="user-comments-list">
        <template v-if="!viewAll && data.length !== 0">
            <CommentItem v-for="(comment, commentIndex) in data.slice(0, 2)" :key="commentIndex" :feed="feed" :comment="comment" :commentIndex="commentIndex" :feedIndex="feedIndex" @onCreateSubComment="handleOnCreateSubComment" />
        </template>
        <template v-else>
            <CommentItem v-for="(comment, commentIndex) in data" :key="commentIndex" :feed="feed" :comment="comment" :commentIndex="commentIndex" :feedIndex="feedIndex" @onCreateSubComment="handleOnCreateSubComment" />
        </template>
    </ul>

    <span class="flex justify-end" v-if="data.length > 2">
        <a class="view-all-link inline-flex items-center text-md" @click="toggleViewAllComments($event)">
            <span>{{ viewAll ? "Hide Comments" : "View All Comments" }}</span>
            <feather-icon :icon="$vs.rtl ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="h-5 w-5"></feather-icon>
        </a>
    </span>

    <div class="post-comment" v-if="enableCommentInput && !hideCommentInput">
        <!-- <vs-textarea
        label="Add Comment"
        class="mb-2"
        :class="{ hidden: isEditCommentActive }"
        v-model="commentInput[feedIndex]"
      /> -->
        <CpTextarea ref="customTextarea" @input="updateInput" placeholder="Write your comment here..." :class="{ hidden: isEditCommentActive }" content='Write your comment' />

        <vs-button size="small" class="mt-2" :class="{ hidden: isEditCommentActive }" @click="postComment($event, feedIndex)">Comment</vs-button>
    </div>

</div>
</template>

<script>
import CommentItem from './CommentItem'
import CpTextarea from '@/components/CpTextarea'
import axios from 'axios'

export default {
    name: 'Comment',
    components: {
        CommentItem,
        CpTextarea
    },
    props: ['data', 'feed', 'feedIndex', 'enableCommentInput'],
    data() {
        return {
            commentInput: [],
            editCommentInput: '',
            isEditCommentActive: false,
            subCommentInput: '',
            viewAll: false,
            hideCommentInput: false,
        }
    },
    methods: {
        async postComment(event, feedStateIndex) {

            if (this.commentInput[feedStateIndex].length !== 0) {
                this.$store.dispatch('newsfeed/createComment', {
                    feedStateIndex,
                    feed: this.feed,
                    content: this.commentInput[feedStateIndex],
                    author: this.$store.state.auth.userData.name
                })
            }
            this.commentInput[feedStateIndex] = ''
            this.$refs.customTextarea.$el.innerText = ''

            

            if (this.feed.authorRef.enablePostNotification !== false) {

                this.$store.dispatch('newsfeed/sendMail', {
                    user_name: this.$store.state.auth.userData.name,
                    activity_user: this.feed.authorRef.displayName,
                    activity: 'COMMENT',
                    template_code: 'send_notification',
                    activity_user_mail: this.feed.authorRef.email,
                    post_content: this.feed.content,
                    activity_user_image_url: this.feed.authorRef.photoURL,
                    post_image_url: this.feed.mediaFiles[0],
                    profile_url: this.$store.state.auth.userData.photoURL,
                    post_url: `https://mypals.app/post?postId=${this.feed.id}`

                })
            }
        },
        toggleViewAllComments() {
            this.viewAll = !this.viewAll
        },
        updateInput() {
            this.commentInput[this.feedIndex] = this.$refs.customTextarea.$el.innerText
        },
        handleOnCreateSubComment(isCreateSubCommentActive) {
            this.hideCommentInput = isCreateSubCommentActive
        }
    }
}
</script>

<style scoped>
.view-all-link:hover,
.like-button:hover {
    cursor: pointer;
}
</style>
