<template>
  <transition name="fade">
    <div v-if="isShow">
        <div v-if="percent < 100.0">
         <!-- <p>Uploading {{ objectName }}</p> -->
          <p class="self-center">Posting to newsfeed...</p>
        </div>
        <div class="flex" v-if="percent === 100.0">
          <feather-icon icon="CheckIcon" class="self-center icon-color inline"></feather-icon>
        <!--  <p class="self-center">Upload {{ objectName }} Completed!</p> -->
        <p class="self-center">Posting to newsfeed...</p>
        </div>
        <vs-progress :percent="percent" :height="4" color="#28C76F" v-if="percent < 100.0"></vs-progress>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ProgressBar',
  data () {
    return {
      objectName: '',
      percent: 0,
      isShow: false
    }
  }
}
</script>

<style scoped>
.icon-color {
  color: #28C76F;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s !important;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0 !important;
}
</style>