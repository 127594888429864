<template>
<div class="mt-2">
  <ul class="user-comments-list">
    <li v-for="(subComment, subCommentIndex) in data" :key="subCommentIndex" class="commented-user flex items-start mb-4 w-full" >
      <div class="leading-tight ml-8 w-full">
        <div class="flex items-center">
          <div class="flex items-center user-profile-group-btn cursor-pointer" @click="goToProfilePage(subComment)">
            <AvatarHolder :profileData="subComment.authorRef" :photoURL="subComment.authorRef.photoURL" :size="'30px'" />
            <p class="ml-2 text-lg font-semibold">{{ subComment.authorRef.displayName }}</p>
          </div>
          <!-- edit sub-comment dropdown -->
          <div v-if="subComment.author === currentUsername" class="ml-auto">
            <vs-dropdown vs-custom-content vs-trigger-click class="vx-navbar-dropdown">
              <vs-button type="flat" color="gray" icon="more_horiz" size="large" class="ml-4" ></vs-button>
              <vs-dropdown-menu>
                <ul>
                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="editSubComment(subComment, subCommentIndex)">
                      <span>Edit comment</span>
                    </li>
                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="deleteSubComment(subComment, feedIndex, commentIndex, subCommentIndex)">
                      <span>Delete comment</span>
                    </li>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
        <!-- subcomment content -->
        <div class="text-md comment-content" :class="{ hidden: isEditSubCommentActive[subCommentIndex] }" v-html="$options.filters.formatPostContent(subComment.content)"></div>

        <!-- edit sub-comment -->
        <div :class="{ hidden: !isEditSubCommentActive[subCommentIndex] }" class="mt-2">
          <CpTextarea ref="editSubCommentTextarea" @input="updateEditSubComment(subCommentIndex)" />
          <!-- <vs-textarea v-model="editSubCommentInput" label="Edit Reply Comment"/> -->
          <div class="mt-2">
            <vs-button size="small" @click="submitEditSubComment(subCommentIndex, subComment)">Submit</vs-button>
            <vs-button type="line" size="small" @click="cancelEditSubComment(subCommentIndex)">Cancel</vs-button>
          </div>
        </div>
        <!-- end edit sub-comment -->

        <div class="mt-2">
          <div class="flex">
            <div class="flex items-center cursor-pointer ml-2 subcomment-btn" @click="toggleLikeSubComment(feedIndex, commentIndex, subCommentIndex, subComment)">
            <feather-icon 
              icon="HeartIcon" 
              :svgClasses="{'h-4 w-4 text-danger fill-current stroke-current': subComment.isLiked, 'h-4 w-4 stroke-current': !subComment.isLiked }" 
              class="mr-2 cursor-pointer" ></feather-icon>
              <span>Like</span>
            </div>

            <div class="flex items-center cursor-pointer ml-2 subcomment-btn" @click="createSubComment()">
            <feather-icon icon="MessageSquareIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer"></feather-icon>
            <span>Reply</span>
            </div>

          </div>
        </div>
      </div>

    </li>
  </ul>

</div>
</template>

<script>
import AvatarHolder from './AvatarHolder'
import CpTextarea from '@/components/CpTextarea'

export default {
  name: 'SubComment',
  props: ['data', 'isEditCommentActive', 'feedIndex', 'commentIndex', 'comment'],
  components: {
    AvatarHolder,
    CpTextarea
  },
  data () {
    return {
      isEditSubCommentActive: [],
      editSubCommentInput: ''
    }
  },
  computed: {
    currentUsername () {
      return this.$store.state.auth.userData.name
    }
  },
  methods: {
    toggleLikeSubComment (feedIndex, commentIndex, subCommentIndex, subComment) {
      this.$store.dispatch('newsfeed/toggleLikeSubComment', {
        comment: this.comment,
        feedIndex,
        commentIndex,
        subCommentIndex,
        subComment
      })
    },
    createSubComment () {
      this.$emit('onCreateSubComment')
    },
    editSubComment (subComment, subCommentIndex) {
      this.$refs.editSubCommentTextarea[subCommentIndex].$el.innerText = subComment.content 
      if (subCommentIndex > this.isEditSubCommentActive.length) {
        this.isEditSubCommentActive[subCommentIndex] = true
      } else {
        this.isEditSubCommentActive.splice(subCommentIndex, 1, true)
      }
    },
    submitEditSubComment (subCommentIndex, subComment) {
      this.$store.dispatch('newsfeed/updateSubComment', {
        comment: this.comment,
        subCommentIndex,
        subComment,
        editSubCommentInput: this.editSubCommentInput
      }).then(() => this.isEditSubCommentActive.splice(subCommentIndex, 1, false))
    },
    deleteSubComment (subComment, feedIndex, commentIndex, subCommentIndex) {
      this.$store.dispatch('newsfeed/deleteSubComment', {
        comment: this.comment,
        subCommentIndex,
        subComment
      })
    },
    cancelEditSubComment (subCommentIndex) {
      this.isEditSubCommentActive.splice(subCommentIndex, 1, false)
    },
    updateEditSubComment (subCommentIndex) {
      this.editSubCommentInput = this.$refs.editSubCommentTextarea[subCommentIndex].$el.innerText
    },
    goToProfilePage (subComment) {
      if (this.subComment.authorRef.isPartner) {
        this.$router.push(`partner/${subComment.author}`)
      } else {
        this.$router.push(`/profile/${subComment.author}`)
      }
    }
  }
}
</script>

<style scoped>
.circle {
  border-radius: 50% !important;
}
.comment-content {
  margin-top: 0.1rem;
  padding: 0.8rem 0.3rem;
  background-color:papayawhip;
  border-radius: 5%;
}

.user-profile-group-btn {
  border-radius: 10px;
  padding: 0.2rem 0.5rem;
}

.user-profile-group-btn:hover {
  background-color: #ddd;
}

</style>