<template>
  <div class="w-full">
    <template v-if="items.length === 1">
      <img class="single-image image-orientation w-full" :src="items[0]" @click="activePopup = true"/>
      <!-- <img v-img-orientation-changer class="single-image image-orientation w-full" :src="items[0]" @click="activePopup = true"/> -->
      
    </template>
    <template v-else-if="items.length === 2">
      <div class="flex flex-row">
        <div class="w-1/2 m-1">
          <img class="w-full image" :src="items[0]" alt="user-upload-image" @click="activePopup = true"/>
        </div>
        <div class="w-1/2 m-1">
          <img class="w-full image" :src="items[1]" alt="user-upload-image" @click="activePopup = true"/>
        </div>
      </div>
    </template> 
    <template v-else>
      <div class="flex flex-row">
        <img class="w-1/2 m-1 image" :src="items[0]" alt="user-upload-image" @click="activePopup = true"/>

        <div class="flex flex-col w-1/2">
          <img class="w-full m-1 image" :src="items[1]" alt="user-upload-image" @click="activePopup = true"/>
          <div class="w-full">
            <div class="flex justify-center content-center w-full m-1 more-images" :style="bg(items[2])" @click="activePopup = true">
              <span v-if="items.length > 3" class="more-images-text">{{ items.length - 3 }} +</span>
            </div>
          </div>
        </div>

      </div>
    </template>
    
    <vs-popup title="" :active.sync="activePopup">
      <carousel :perPage="1" :paginationEnabled="false">
        <slide v-for="(image, index) in items" :key="index">
          <div class="w-full">
            <img :src="image" alt="user-upload-image" class="carousel-image" @click='checkExif(image)'/>
            
          </div>
        </slide>
      </carousel>
    </vs-popup>
  </div>
</template>

<script>
import EXIF from "exif-js";
export default {
  name: 'PictureGallery',
  props: ['items',
  ],

  data () {
    return {
      activePopup: false,
    }
  },

  methods: {
    bg (src) {
      return `background-image: url(${src});`
    },
    goToSinglePost () {
      this.$router.push(`/post/?postId=${this.feed.sharePostID}`)
    },
    checkExif(val){
      
   console.log(val)
        //        EXIF.getData(val, function() {
        // var make = EXIF.getTag(val, "Make");
        // var model = EXIF.getTag(val, "Model");
        // console.log(make)
        // console.log(model)
    // });
    }
  }
}
</script>

<style scoped>
.more-images-text {
  margin-top: 70px;
  color: white;
  font-size: 40px;
}

.more-images:hover, .image:hover {
  opacity: 0.7;
  cursor: pointer;
}

.carousel-image {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.more-images {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
}

</style>