<template>
  <vx-card class="mt-4 mt-mobile" @click="handleClick">
    <div class="post-card-content">
      <template v-if="actor !== '' && action !== '' ">
        <div class="mb-2"><span class="font-bold">{{ actor + ' '}}</span>
          <span v-if="action === 'like'">liked a post</span>
          <span v-if="action === 'comment'">commented on a post</span></div>
        <vs-divider class="mb-4" />
      </template>
      <PostHeader
        :feed="feed" :feedIndex="feedIndex"
        :displayPostOption="displayPostOption"
        :displayLikeAndShare="displayLikeAndShare"
        :displayShare="displayShare"
        @toggleEditPostForm="$emit('toggleEditPostForm')"/>
      <PostContent :feed="feed" :feedIndex="feedIndex" :showImages="true" :displayLikeAndCommentCount="displayLikeAndCommentCount"/>
      <vs-divider class="my-2" />
      <Comment v-if="displayComment"
        :data="feed.comments.items"
        :feed="feed" :feedIndex="feedIndex"
        :enableCommentInput="true"
        />
    </div>
  </vx-card>
</template> 

<script>
import PostHeader from './PostHeader'
import PostContent from './PostContent'
import Comment from '../Comment'


export default {
  name: 'Post',
  props: ['feed', 
    'feedIndex', 
    'displayComment', 
    'displayPostOption', 
    'displayLikeAndShare', 
    'displayShare', 
    'displayLikeAndCommentCount', 
    'actor', 
    'action'],
  components: {
    PostHeader,
    PostContent,
    Comment
  },
  methods: {
    handleClick () {
      this.$emit('onClick')
    }
  }
}
</script>

<style>
.like-button:hover {
  cursor: pointer;
}
.post-search-result {
  transition: 0.3s;
}
.post-search-result:hover > .post-card-content {
  margin-top: 1em !important;
  box-shadow: none;
}
</style>