<template>
<div ref="customEditor" class="mt-2 mb-2 p-2 textarea-editor" contenteditable="true" @input="$emit('input')" @focus="initialText = false" @blur="showInitialText">
    <span v-if="initialText" class="">{{content}}</span>
    
</div>
</template>

<script>
export default {
    name: 'CpTextarea',
    props: ['placeholder','content'],
    data() {
        return {
            initialText: true
        }
    },
    methods: {
        placeCaretAtEnd(el) {
            el.focus()
            if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
                const range = document.createRange()
                range.selectNodeContents(el)
                range.collapse(false)
                const sel = window.getSelection()
                sel.removeAllRanges()
                sel.addRange(range)
            } else if (typeof document.body.createTextRange !== 'undefined') {
                const textRange = document.body.createTextRange()
                textRange.moveToElementText(el)
                textRange.collapse(false)
                textRange.select()
            }
        },
        regexConverter() {
            const editor = this.$refs.customEditor
            let str = editor.innerText
            const matches = [...str.matchAll(/( |^|\n)#\w+/g)]
            matches.map(m => {
                m[0] = m[0].trim()
                str = str.replaceAll(m[0], `<span class="tag" contenteditable="false">${m[0]}</span>`)
            })
            editor.innerHTML = str

            this.$emit('input')
        },
        createHashtagElement() {
            const editor = this.$refs.customEditor
            let str = editor.innerText
            const matches = [...str.matchAll(/( |^|\n)#\w+/g)]
            matches.map(m => {
                m[0] = m[0].trim()
                str = str.replaceAll(m[0], `<span class="tag" contenteditable="false">${m[0]}</span>`)
            })
            editor.innerHTML = str
            this.placeCaretAtEnd(editor)
        },
        showInitialText() {
            if (this.$refs.customEditor.innerText.length === 0) {
                this.initialText = true
            }
        }
    },
    mounted() {
        const editor = this.$refs.customEditor

        for (const child of editor.childNodes) {
            if (parseInt(child.getAttribute('line')) === this.currentLine) {
                this.lineRef = child
                for (const block of child.childNodes) {
                    if (parseInt(block.getAttribute('block')) === this.currentBlock) {
                        this.blockRef = block
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">
.textarea-editor {
    border: solid 0.5px #ddd;
    border-radius: 10px;
    height: 80px;
    overflow-y: auto;
    font-size: 1rem;
    white-space: pre-wrap;
}

.tag {
    z-index: 100;
    color: var(--primary);
}
</style>
