<template>
  <div>
     <div class="post-content post-content-connect">

        <p class="" v-html="$options.filters.formatPostContent(feed.content)"></p>

        <div class="text-right mt-3 mb-3" style="white-space: nowrap;">

       </div>  


        <Post v-if="feed.sharePost" :feed="feed.sharePost"
          @onClick="goToSinglePost"
          class="cursor-pointer"
          :actor="''"
          :action="''"
          :displayComment="false"
          :displayPostOption="false"
          :displayLikeAndShare="false"
          :displayLikeAndCommentCount="false" />
        <div v-if="ogMetaData" @click="goToWebsite(ogMetaData.requestUrl)">
          <vs-card class="cursor-pointer">
            <div class="static">
              <div>
                <div slot="media" v-if="ogMetaData.ogVideoUrl && ogMetaData.ogVideoType ">
                  <iframe class="border-none w-full og-video-player" :src="ogMetaData.ogVideoUrl" />
                </div>
                <div slot="media" v-if="ogMetaData.ogImageUrl && !ogMetaData.ogVideoUrl && !ogMetaData.ogVideoType">
                  <img :src="ogMetaData.ogImageUrl" class="og-img"/>
                </div>
                <div>
                  <h5 class="mt-4 mb-2 font-medium">{{ ogMetaData.ogTitle | truncate(50) }}</h5>
                  <span v-if="ogMetaData.ogSiteName">{{ ogMetaData.ogSiteName }}</span>
                  <p class="text-base whitespace-pre-wrap">{{ ogMetaData.ogDescription }}</p>
                </div>
              </div>
            </div>
          </vs-card> 
        </div> 
      </div>
      <div class="post-media-container mb-6 mt-4">
          <PictureGallery v-if="feed.mediaFiles && feed.mediaFiles.length !== 0 && showImages" :items="feed.mediaFiles" :singlePost='feed'/>
      </div>
      <div class="post-video-container">
        <video v-if="feed.videoURL"  :key="feed.videoURL" controls class="w-full" controlsList="nodownload">
          <source :src="feed.videoURL" />
        </video>
      </div>
      <div v-if="displayLikeAndCommentCount" class="flex justify-between">
          <div class="flex items-center">
              <div class="flex items-center">
                <feather-icon class="mr-2" icon="HeartIcon" svgClasses="h-5 w-5"></feather-icon> 
                <span >{{ feed.likes.items.length | formatLikeCount }}</span>
              </div>
          </div>
          <div class="flex items-center">
            <feather-icon class="mr-2" icon="MessageSquareIcon" svgClasses="h-5 w-5"></feather-icon>
            <span>{{ feed.comments.items.length | formatCommentCount }}</span>
          </div>
      </div>

      

    


  </div>
</template>

<script>
import PictureGallery from '../PictureGallery.vue'
export default {
  name: 'PostContent',
  props: ['feed', 'showImages', 'displayLikeAndCommentCount'],
  data () {
    return {
      viewAll: false,
      popupSell: false,
      popupBuy: false
    }
  },
  computed: {
    ogMetaData () {
      if (this.feed.ogMetaData && this.feed.ogMetaData.items && this.feed.ogMetaData.items.length !== 0) return this.feed.ogMetaData.items[0]
      else return null
    }
  },
 
  
  methods: {
    toggleViewAll () {
      this.viewAll = !this.viewAll
    },
    goToWebsite (url) {
      window.open(url, '__blank')
    },
    goToSinglePost () {
      this.$router.push(`/post/?postId=${this.feed.sharePostID}`)
    }
  },
  components: {
    PictureGallery,
    Post: () => import('./Post')
  }
}
</script>

<style lang="scss" scoped>
.post-content > p {
  white-space: pre-line;
  word-break: break-all;
}

a {
  color: #007bff !important;
}

// .og-container:hover {
//   opacity: 0.5;
// }

.og-img {
  object-fit: cover;
  width: 100%;
  max-height: 400px;
}

.og-video-player {
  height: 400px
}
.sell-nft li, .buy-nft li{
  padding-top:0.75rem !important;
  padding-bottom:0.75rem !important;
  border-bottom:1px solid #dae1e7;;
}

</style>

<style>
@media (min-width: 792px){
.popup-nft.con-vs-popup .vs-popup {
  width:500px !important;
}
}
</style>