<template>
<div class="">

    <div>
        <b-nav>
            <b-nav-item-dropdown class="post-option-dropdown" id="my-nav-dropdown" right>
                <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" svgClasses="w-5 h-5" />
                </template>

                <template v-if="feed.author !== currentUsername && currentUsername !== 'Kiran' && currentUsername !== 'PoojaRanjith' && currentUsername !== 'Coursepal' && currentUsername !== 'BRIKS'">
                    <b-dropdown-item v-if="isFollowed" @click="unfollowCurrentAuthor($event, feed.author)">
                        <span> Unfollow</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-else @click="followUser($event, feed.author)">
                        <span>Follow</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="popupActive=true">
                        <span> Report Post</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="blockUser=true">
                        <span> Block User</span>
                    </b-dropdown-item>
                </template>

                <template v-else-if="feed.author === currentUsername || currentUsername === 'Kiran' || currentUsername === 'PoojaRanjith' || currentUsername === 'Coursepal' || currentUsername === 'BRIKS'">
                    <b-dropdown-item @click="editPost">
                        <span> Edit this post</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="activateDeleteConfirm = true">
                        <span>Delete this post</span>
                    </b-dropdown-item>
                </template>

            </b-nav-item-dropdown>
            <vs-popup title="Confirm Delete Post" :active.sync="activateDeleteConfirm">
                <h4>Do you want to delete this post?</h4>
                <div class="mt-3 float-right">
                    <vs-button class="mr-2" size="small" @click="deletePost">Delete</vs-button>
                    <vs-button size="small" @click="activateDeleteConfirm = false">Cancel</vs-button>
                </div>
            </vs-popup>
        </b-nav>
    </div>

    <!-- <vs-dropdown vs-custom-content class="vx-navbar-dropdown">
    <vs-button type="flat" color="grey" icon="more_horiz" size="large" class="ml-2" radius></vs-button>
    <vs-dropdown-menu>
        <template v-if="feed.author !== currentUsername && currentUsername !== 'Kiran' && currentUsername !== 'PoojaRanjith' && currentUsername !== 'Coursepal' && currentUsername !== 'BRIKS'">
            <vs-dropdown-item 
              class="flex py-2 px-4 cursor-pointer"
              v-if="isFollowed"
              @click="unfollowCurrentAuthor($event, feed.author)">
              <span class="text-md">Unfollow</span>
            </vs-dropdown-item>

            <vs-dropdown-item v-else
              class="flex py-2 px-4 cursor-pointer"
              @click="followUser($event, feed.author)">
              <span class="text-md">Follow</span>
            </vs-dropdown-item>

            <vs-dropdown-item 
              class="flex py-2 px-4 cursor-pointer"
              @click="popupActive=true" 
              >
              <span class="text-md">Report Post</span>
            </vs-dropdown-item>

            <vs-dropdown-item 
              class="flex py-2 px-4 cursor-pointer"
              @click="blockUser=true" 
              >
              <span class="text-md">Block User</span>
            </vs-dropdown-item>
        </template>

        <template v-else-if="feed.author === currentUsername || currentUsername === 'Kiran' || currentUsername === 'PoojaRanjith' || currentUsername === 'Coursepal' || currentUsername === 'BRIKS'">
          <vs-dropdown-item 
            class="flex py-2 px-4 cursor-pointer"
            @click="editPost">
            <span class="text-md">Edit this post</span>
          </vs-dropdown-item>
          <vs-dropdown-item 
            class="flex py-2 px-4 cursor-pointer"
            @click="activateDeleteConfirm = true">
            <span class="text-md">Delete this post</span>
          </vs-dropdown-item>
        </template>
    </vs-dropdown-menu>
    <vs-popup title="Confirm Delete Post" :active.sync="activateDeleteConfirm">
      <h4>Do you want to delete this post?</h4>
      <div class="mt-2 float-right">
        <vs-button class="mr-2" size="small" @click="deletePost">Delete</vs-button>
        <vs-button size="small" @click="activateDeleteConfirm = false">Cancel</vs-button>
      </div>
    </vs-popup>
  </vs-dropdown> -->

    <!-- Report Popup -->

    <div>
        <vs-popup title="Report This Post" :active.sync="popupActive">
            <div>
                <h5>Please report this content asap to keep our community safe.</h5>
                <vs-select class="w-full" label="Report Reasons" v-model="reportList" multiple>
                    <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in reports"></vs-select-item>
                </vs-select>
                <div class="text-right">
                    <vs-button color="primary" class="mt-4" @click="submitReport">Report</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
    <!-- End Report Popup -->

    <!-- blockUser Popup -->

    <div>
        <vs-popup title="Block This User" :active.sync="blockUser">
            <div>
                <h5>Please tell the reason to block this user.</h5>
                <vs-select class="w-full" label="Select Reasons" v-model="reportList" multiple>
                    <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in reports"></vs-select-item>
                </vs-select>
                <div class="text-right">
                    <vs-button color="primary" class="mt-4" @click="submitReport(); BlockCurrentAuthor($event, feed.author);">Submit</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
    <!-- End blockUser Popup -->

</div>
</template>

<script>
import {
    API,
    graphqlOperation
} from '@aws-amplify/api'
import {
    getFollow
} from '@/graphql/queries'
import {
    createWarningObject
} from '@/utils'

export default {
    name: 'PostOptionDropdown',
    props: ['feed', 'feedIndex'],
    data() {
        return {
            activateDeleteConfirm: false,
            isFollowed: false,
            popupActive: false,
            blockUser: false,
            feedData: this.feed,
            reportList: [],
            reports: [
                'Irrelevant content',
                'Fake information',
                'Spam content',
                'Harassment',
                'Nudity content'
            ]
        }
    },
    computed: {
        currentUsername() {
            return this.$store.state.auth.userData.name
        },
        currentChannel() {
            return this.$store.state.channel.currentChannel
        },
        PUBLIC_CHANNEL_ID() {
            return this.$store.state.channel.PUBLIC_CHANNEL.channel_id
        }
    },
    methods: {
        editPost() {
            this.$emit('toggleEditPostForm')
        },

        deletePost() {
            if (this.$route.path === '/post') {
                this.$store.dispatch('newsfeed/deleteSinglePost', this.feed.id).then(() => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Post delete success',
                        iconPack: 'feather',
                        icon: 'icon-check',
                        position: 'top-right',
                        color: 'success'
                    })
                    this.$router.push('/')
                })
            } else {
                this.$store.dispatch('newsfeed/deletePost', this.feedIndex).then(() => {
                    this.activateDeleteConfirm = false
                })
            }
        },
        async checkFollow() {
            if (!this.feedData.author) {
                this.isFollowed = false
                return
            }

            const res = await API.graphql(graphqlOperation(getFollow, {
                user: this.feedData.author,
                follower: this.$store.state.auth.userData.name
            }))
            if (res.data.getFollow) {
                this.isFollowed = true
            } else {
                this.isFollowed = false
            }
        },
        followUser() {
            this.$store.dispatch('follow/followUser', this.feed.author).then(() => {
                this.isFollowed = true
            })
       
            if (this.feed.authorRef.enablePostNotification !== false) {
                this.$store.dispatch('newsfeed/sendMail', {
                    user_name: this.$store.state.auth.userData.name,
                    activity_user: this.feed.authorRef.displayName,
                    activity: 'FOLLOW',
                    template_code: 'send_notification',
                    activity_user_mail: this.feed.authorRef.email,
                    post_content: null,
                    activity_user_image_url: this.$store.state.auth.userData.photoURL,
                    post_image_url: null,
                    profile_url: `https://mypals.app/profile/${this.$store.state.auth.userData.name}`,
                    post_url: null
                })
            }
        },
        unfollowCurrentAuthor() {
            this.$store.dispatch('follow/unfollowCurrentAuthor', this.feed.author).then(() => {
                this.isFollowed = false
            })
        },
        BlockCurrentAuthor() {
            this.$store.dispatch('follow/unfollowCurrentAuthor', this.feed.author).then(() => {
                this.isFollowed = false
            })
        },
        submitReport() {
            if (this.reportList.length !== 0) {
                this.$store.dispatch('newsfeed/reportPost', {
                    feed: this.feed,
                    feedIndex: this.feedIndex,
                    reports: this.reportList,
                    notify: this.$vs.notify
                }).then(() => {
                    this.popupActive = false
                    this.blockUser = false
                })
            } else {
                this.$vs.notify(createWarningObject('Warning', 'You have not selected any report reason'))
            }
        }
    },
    mounted() {
        this.checkFollow()
    }
}
</script>

<style lang="css" scoped>
.circle {
    border-radius: 50% !important;
}
</style>
